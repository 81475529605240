body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", Arial, sans-serif;
  background-color: #fafafa;
  color: #333;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.container {
  max-width: 600px;
  padding: 20px;
  text-align: center;
}

header {
  margin-bottom: 40px;
}

.profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

h1 {
  font-size: 36px;
  margin-top: 20px;
}

h2,
h3 {
  font-size: 18px;
  font-weight: normal;
  color: #777;
  margin-top: 5px;
}

main p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.links a {
  color: #0073e6;
  text-decoration: none;
  margin: 5px 15px;
  font-size: 16px;
}

.links a:hover {
  text-decoration: underline;
}

.business-info {
  font-size: 14px;
  color: #555;
}

.business-info p {
  margin: 5px 0;
}
